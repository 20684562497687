import React from 'react';
import '../../App.css';

export default function Products() {
  return <>
  <h1 className='products'>About Us</h1>
  <ul className="about-us-container">
      <h2>About Sai Shradha Tours and Travels</h2>
      <li>
        Welcome to Sai Shradha Tours and Travels, your trusted partner in creating unforgettable travel experiences.
        With years of expertise in the travel industry, we are dedicated to providing exceptional services and
        crafting tailor-made journeys that suit your preferences.
      </li>
      <li>
        Our team of experienced professionals is passionate about travel and committed to ensuring that every
        aspect of your trip is seamless. From personalized itineraries to comfortable accommodations and
        reliable transportation, we strive to make your journey with us memorable and stress-free.
      </li>
      <li>
        Discover new destinations, immerse yourself in different cultures, and let Sai Shradha Tours and Travels
        be your companion in exploring the beauty of the world.
      </li>
    </ul>
  </> 
}
