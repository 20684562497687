import React from "react";
import "../../App.css";

export default function SignUp() {
  return (
    <>
      <h1 className="sign-up">Contact Us</h1>
      <div className="contact-container">
        <div class="container">
          <div class="content">
            <div class="left-side">
              <div class="address details">
                <i class="fas fa-map-marker-alt"></i>
                <div class="topic">Address</div>
                <div class="text-one">G-85 vardhman premium mall</div>
                <div class="text-two">outer ring road pitampura</div>
                <div class="text-two">North Delhi 110085</div>
              </div>
              <div class="phone details">
                <i class="fas fa-phone-alt"></i>
                <div class="topic">Phone</div>
                <div class="text-one">7770917641</div>
              </div>
              <div class="email details">
                <i class="fas fa-envelope"></i>
                <div class="topic">Email</div>
                <div class="text-one">Shradhatmr@gmail.com</div>
              </div>
            </div>
            <div class="right-side">
              <div class="topic-text">Send us a message</div>
              <p>
                If you have any work from me or any types of quries related to
                my tutorial, you can send me message from here. It's my pleasure
                to help you.
              </p>
              <form
                action="mailto:dhullsaurabh3111@gmail.com"
                method="post"
                encType="text/plain"
              >
                <div class="input-box">
                  <input type="text" placeholder="Enter your name" />
                </div>
                <div class="input-box">
                  <input type="text" placeholder="Enter your email" />
                </div>
                <div class="button">
                  <input type="button" value="Send Now" />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
