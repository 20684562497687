import React from 'react';
import '../../App.css';
import CardItem from '../CardItem';
import car1 from '../../img/car1.jpg';
import car2 from '../../img/car2.jpg';
import car3 from '../../img/car3.jpg';
import car4 from '../../img/car4.jpg';

export default function Services() {
  return <>
  <h1 className='services'>Our Services</h1>

  <div className='cards'>
      <h1>Check out Our Services</h1>
      <div className='cards__container'>
        <div className='cards__wrapper'>
          <ul className='cards__items'>
            <CardItem
              src={car1}
              text='Explore the hidden waterfall deep inside the Amazon Jungle'
              label='Adventure'
              path='/services'
            />
            <CardItem
              src={car2}
              text='Travel through the Islands of Bali in a Private Cruise'
              label='Luxury'
              path='/services'
            />
          </ul>
          <ul className='cards__items'>
            <CardItem
              src={car3}
              text='Set Sail in the Atlantic Ocean visiting Uncharted Waters'
              label='Mystery'
              path='/services'
            />
            <CardItem
              src={car4}
              text='Experience Football on Top of the Himilayan Mountains'
              label='Adventure'
              path='/products'
            />
          </ul>
        </div>
      </div>
    </div>
  </>
}
